import {Routes} from '@angular/router';
import { BaseLayoutComponent } from '../layout/base-layout/base-layout.component';
import { SingleFormComponent } from '../layout/single-form/single-form.component';
import {AuthGuard} from "../core/auth/guards/auth.guard";
import {PublicProfileComponent} from "../user/public-profile/public-profile.component";
import {ContestReadComponentComponent} from "../content/contest-read-component/contest-read-component.component";
import {DEFAULT_PAGE_METADATA} from "../content/seo.service";

export const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      { path: '', loadChildren: () => import('../pages/pages.routes') },
    ]
  },
  {
    path: '',
    component: SingleFormComponent,
    children: [
      {path: '', loadChildren: () => import('../core/auth/auth.routes')},
    ]
  },
  {
    path: 'utwory',
    children: [
      { path: '', loadChildren: () => import('../content/content.routes') },
    ]
  },
  {
    path: 'konkursy',
    pathMatch: 'full',
    redirectTo: '/konkursy/konkurs-literacki-ai',
  },
  {
    path: 'konkursy/:slug',
    pathMatch: 'full',
    component: ContestReadComponentComponent,
    resolve: {
      seo: () => {
        return {...DEFAULT_PAGE_METADATA,
          title: "Konkurs AI Literacki 2025 | Stwórz przyszłość literatury z AI",
          description: "Weź udział w pierwszym w Polsce konkursie literackim, w którym sztuczna inteligencja spotyka się z kreatywnością człowieka. Napisz wiersz, opowiadanie lub esej z wykorzystaniem AI i wygraj atrakcyjne nagrody! Zgłoszenia do 28 lutego 2025 roku.",
          twitter_description: "Połącz siłę AI z własną kreatywnością i weź udział w konkursie! Zgłoszenia do 28 lutego.",
          og_description: "Weź udział w pierwszym w Polsce konkursie literackim, w którym sztuczna inteligencja spotyka się z kreatywnością człowieka. Napisz wiersz, opowiadanie lub esej z wykorzystaniem AI i wygraj atrakcyjne nagrody! Zgłoszenia do 28 lutego 2025 roku.",
          image: "https://konkurs.gieldatekstow.ai/wp-content/uploads/Logotyp.png",
          keywords: "konkurs literacki AI, poezja AI, proza AI, sztuczna inteligencja w literaturze, konkurs literacki 2025, AI w twórczości, tworzenie tekstów AI"
        }
      },
    }
  },
  {
    path: 'uzytkownik/:username/profil',
    component: BaseLayoutComponent,
    children: [
      { path: '', component: PublicProfileComponent },
    ]
  },
  {
    path: 'konto',
    children: [
      { path: '', loadChildren: () => import('../user/user.routes'), canActivate: [AuthGuard] },
    ]
  },
  {
    path: '**',
    redirectTo: 'errors/404'
  }
];
