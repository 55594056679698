import {Component, computed, inject, OnInit} from '@angular/core';
import {DatePipe, NgOptimizedImage} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {SeoService} from "../seo.service";
@Component({
  selector: 'app-contest-read-component',
  standalone: true,
  imports: [
    DatePipe,
    NgOptimizedImage,
  ],
  templateUrl: './contest-read-component.component.html',
  styleUrl: './contest-read-component.component.scss'
})
export class ContestReadComponentComponent implements OnInit {

  private _router = inject(Router);

  public content: any;

  public date = new Date(2025, 0, 18, 12, 0, 0, 0);

  currentUrl = computed(() => {
    return this._router.url
  })

  private activatedRoute = inject(ActivatedRoute);
  private seoService = inject(SeoService);
  ngOnInit() {
    this.activatedRoute.data.subscribe({
      next: (data) => {
        this.seoService.setMetadata(data['seo']);
      },
      error: (error) => {
        console.log(error);
      }
    })
  }
}
