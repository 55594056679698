<div id="content">
  <div class="article-body">
    <article class="content-container" itemprop="mainEntity" itemscope itemtype="https://schema.org/ShortStory">

      <meta itemscope itemprop="url" content="{{ currentUrl() }}"/>
      <meta itemprop="dateCreated" content="{{ date| date:'c' }}"/>
      <meta itemprop="dateModified" content="{{ date| date:'c' }}"/>
      <meta itemprop="datePublished" content="{{ date| date:'c' }}"/>

      <header>
        <meta itemprop="name" content="Konkurs Literacki AI"/>
        <h1 itemprop="headline" class="md:!leading-[120%]">Konkurs literacki AI</h1>

        <div class="divider"></div>
      </header>

      <div class="content-container">
        <div class="content">
          <p>Z dumą zapraszamy do udziału w pierwszym w Polsce <strong>Konkursie Literackim AI</strong>! To wyjątkowa inicjatywa, która otwiera nowe możliwości twórcze z wykorzystaniem sztucznej inteligencji. Konkurs ma na celu promowanie nowoczesnego podejścia do literatury, edukację w zakresie potencjału AI oraz zachęcanie do refleksji nad jej rolą w procesie twórczym.</p>

          <img class="logo" src="assets/media/ai-contest.png" alt="konkurs ai" />

          <h2>Kto może wziąć udział?</h2>
          <p>Konkurs jest otwarty dla wszystkich, którzy ukończyli 13 lat i mieszkają w Polsce. Osoby niepełnoletnie muszą posiadać zgodę opiekuna. Z udziału wyłączone są osoby zawodowo związane z organizatorem lub sponsorem konkursu oraz ich bliscy.</p>

          <h2>Kategorie konkursowe:</h2>
          <ul>
            <li><strong>Poezja</strong> – zestaw 3–5 wierszy (w tym haiku lub inne formy).</li>
            <li><strong>Proza</strong> – esej, opowiadanie lub nowela (do 50 000 znaków).</li>
            <li><strong>Najlepsze wykorzystanie AI</strong> – innowacyjne i kreatywne podejście w pracy z AI.</li>
          </ul>

          <h2>Harmonogram:</h2>
          <ul>
            <li><strong>05.11.2024 – 28.02.2025</strong>: Nabór prac konkursowych</li>
            <li><strong>01.03.2025 – 30.03.2025</strong>: Ocena prac przez jury</li>
            <li><strong>01.04.2025</strong>: Oddanie prac do głosowania publiczności</li>
            <li><strong>30.04.2025</strong>: Ogłoszenie wyników</li>
          </ul>

          <h2>Pula nagród:</h2>
          <p><strong>7 500 zł + nagrody rzeczowe!</strong></p>

          <p>Prace można zgłaszać poprzez stronę internetową: <a href="https://konkurs.gieldatekstow.ai" target="_blank">konkurs.gieldatekstow.ai</a></p>

          <h2>Ważne linki:</h2>
          <ul>
            <li><a href="https://konkurs.gieldatekstow.ai/regulamin">Regulamin konkursu</a></li>
          </ul>
        </div>
      </div>
    </article>
  </div>
</div>
